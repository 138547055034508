import {onDOMReady} from './dom';

const CSS_PROPERTY_SF_LAYOUT_DEFAULT_COLUMN_WIDTH = '--ksfdef-layout-default-column-width';

let mainElement;
let containerLayoutElement;
const mainDefaultWidth = 1500;
let currentLayoutUnits = {};

export const SCREEN_DESKTOP = 1320;
export const SCREEN_TABLET = 720;

/**
 * @typedef {Object} LayoutUnitContainerSize
 * @property {number} count
 * @property {number} width
 */

/**
 * @typedef {Object} LayoutUnits
 * @property {LayoutUnitContainerSize} mainWidth
 * @property {LayoutUnitContainerSize} mainColumn
 * @property {LayoutUnitContainerSize} mainColumnLg
 * @property {LayoutUnitContainerSize} mainColumnXlg
 * @property {LayoutUnitContainerSize} mainColumnXxlg
 */

/** @returns {LayoutUnits} */
const layoutUnitsCalculate = () => {
    if (!mainElement) {
        mainElement = document.getElementsByTagName('main')[0];
        if (!mainElement && !containerLayoutElement) {
            containerLayoutElement = document.getElementsByClassName('containerLayout')[0];
        }
    }

    const defaultColumnWidth = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue(CSS_PROPERTY_SF_LAYOUT_DEFAULT_COLUMN_WIDTH).replace(/[^0-9]/i, ''));
    const style = document.documentElement.style;
    const mainWidth = (mainElement ?? containerLayoutElement)?.offsetWidth ?? mainDefaultWidth;
    const mainColumnCount = Math.floor(mainWidth / defaultColumnWidth) || 1;
    const isMobile = mainWidth < SCREEN_TABLET;

    currentLayoutUnits = {
        mainWidth: {
            count: 1,
            width: mainWidth,
        },
        mainColumn: {
            count: mainColumnCount,
            width: isMobile ? defaultColumnWidth : Math.round(mainWidth / mainColumnCount),
        },
        mainColumnLg: {
            count: Math.round(mainColumnCount / 1.5) || 1,
            width: isMobile ? defaultColumnWidth : Math.round(mainWidth / ((mainColumnCount / 1.5) || 1)),
        },
        mainColumnXlg: {
            count: Math.round(mainColumnCount / 2) || 1,
            width: Math.round(mainWidth / ((mainColumnCount / 2) || 1)),
        },
        mainColumnXxlg: {
            count: Math.round(mainColumnCount / 2.5) || 1,
            width: Math.round(mainWidth / ((mainColumnCount / 2.5) || 1)),
        },
    };

    style.setProperty('--layout-main-width', `${currentLayoutUnits.mainWidth.width}px`);

    style.setProperty('--layout-main-column-count', `${currentLayoutUnits.mainColumn.count}`);
    style.setProperty('--layout-main-column-width', `${currentLayoutUnits.mainColumn.width}px`);

    style.setProperty('--layout-main-column-lg-count', `${currentLayoutUnits.mainColumnLg.count}`);
    style.setProperty('--layout-main-column-lg-width', `${currentLayoutUnits.mainColumnLg.width}px`);

    style.setProperty('--layout-main-column-xlg-count', `${currentLayoutUnits.mainColumnXlg.count}`);
    style.setProperty('--layout-main-column-xlg-width', `${currentLayoutUnits.mainColumnXlg.width}px`);

    style.setProperty('--layout-main-column-xxlg-count', `${currentLayoutUnits.mainColumnXxlg.count}`);
    style.setProperty('--layout-main-column-xxlg-width', `${currentLayoutUnits.mainColumnXxlg.width}px`);

    return currentLayoutUnits;
};


/** @returns {LayoutUnits} */
export const getLayoutUnits = () => currentLayoutUnits;

window.addEventListener('resize', layoutUnitsCalculate);
onDOMReady(() => layoutUnitsCalculate);
layoutUnitsCalculate();

export default layoutUnitsCalculate;