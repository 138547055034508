import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper/modules';
import {requestIdleCallback} from './dom';
import layoutUnitsCalculate, {getLayoutUnits} from './layoutUnits';

const CLASS_NAME_SWIPER_BUTTON_NEXT = 'swiper-button-next';
const CLASS_NAME_SWIPER_BUTTON_PREV = 'swiper-button-prev';
const CSS_PROPERTY_GRID_NAV_GAP = '--sf-grid-nav-gap';
const COLUMN_SIZE_FULL_WIDTH = 'fullWidth';


class SwiperElement extends HTMLElement {
    constructor() {
        super();
        this.resizeTimeout = null;
        this.resizeObserver = new ResizeObserver((entries) => {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                entries.forEach(() => {
                    this.recalculateSwiperButtonsTopPosition();
                    this.recalculateImageDimensions();
                    this.recalculateSlidesPerView();
                });
            }, 250);
        });
    }

    connectedCallback() {
        requestIdleCallback(() => this.initSwiper());
        this.resizeObserver.observe(this);
    }

    disconnectedCallback() {
        this.resizeObserver.disconnect();
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
    }


    recalculateSwiperButtonsTopPosition() {
        const firstImgElement = this.getElementsByTagName('img')[0];
        const buttonNextElement = this.getElementsByClassName(CLASS_NAME_SWIPER_BUTTON_NEXT)[0];
        const buttonPrevElement = this.getElementsByClassName(CLASS_NAME_SWIPER_BUTTON_PREV)[0];

        if (firstImgElement && buttonNextElement && buttonPrevElement) {
            const computedTop = Math.round(firstImgElement.offsetTop + firstImgElement.height / 2);
            const buttonClientRects = buttonNextElement.getClientRects();
            const maxTop = buttonClientRects.length ? Math.round(buttonNextElement.getClientRects()[0].height / 2) : NaN;
            const finalTop = isNaN(maxTop) ? computedTop : Math.max(computedTop, maxTop);
            buttonNextElement.style.top = `${finalTop}px`;
            buttonPrevElement.style.top = `${finalTop}px`;
        }
    }

    recalculateSlidesPerView() {
        if (!this.swiper) {
            return;
        }

        const {type, columnSize} = this.dataset;
        const layoutUnits = getLayoutUnits();

        if (type === 'imageGallery') {
            this.swiper.params.slidesPerView = layoutUnits.mainColumn.count;
        } else if (type === 'imageSlider') {
            this.swiper.params.slidesPerGroup = layoutUnits.hasOwnProperty(columnSize) ? layoutUnits[columnSize].count : 1;
        }

        this.swiper.update();
    }

    recalculateImageDimensions() {
        if (!this.swiper || this.dataset.type !== 'imageSlider') {
            return;
        }

        const layoutUnits = getLayoutUnits();
        const columnSize = this.dataset.columnSize;
        const columnWidth = layoutUnits[columnSize]?.width || null;
        const availableWidth = layoutUnits.mainWidth?.width || this.swiper.el.offsetWidth;

        const gap = parseInt(this.dataset.gap) || 0;
        const paddingInline = parseInt(this.dataset.paddingInline) || 0;
        const paddingBlock = parseInt(this.dataset.paddingBlock) || 0;

        let columnCount = columnWidth ? Math.max(1, Math.floor(availableWidth / columnWidth)) : 1;
        const totalWidth = availableWidth - (columnCount - 1) * gap;
        const slideWidth = totalWidth / columnCount;

        const slides = this.swiper.el.getElementsByClassName('swiper-slide');

        if (slides.length === 0) {
            return;
        }

        const firstSlide = slides[0];
        const img = firstSlide.getElementsByTagName('img')[0];

        if (!img) {
            return;
        }

        const originalHeight = parseInt(firstSlide.dataset.originalHeight);
        const originalWidth = parseInt(firstSlide.dataset.originalWidth);

        if (!originalWidth || !originalHeight) {
            return;
        }

        let computedWidth;
        if (columnSize === COLUMN_SIZE_FULL_WIDTH) {
            computedWidth = availableWidth - 2 * paddingInline;
        } else {
            computedWidth = Math.min(slideWidth - 2 * paddingInline, originalWidth, columnWidth || originalWidth);
        }

        const computedHeight = computedWidth * (originalHeight / originalWidth);

        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            const img = slide.getElementsByTagName('img')[0];

            if (!img) {
                continue;
            }

            slide.style.width = `${Math.round(computedWidth + 2 * paddingInline)}px`;
            slide.style.height = `${Math.round(computedHeight + 2 * paddingBlock)}px`;

            img.setAttribute('width', Math.round(computedWidth).toString());
            img.setAttribute('height', Math.round(computedHeight).toString());
        }
    }



    initSwiper() {
        const layoutUnits = layoutUnitsCalculate();
        const gap = parseInt(this.dataset.gap);
        const spaceBetween = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue(CSS_PROPERTY_GRID_NAV_GAP).replace(/[^0-9]/i, '')) ?? 0;
        const columnSize = this.dataset.columnSize;
        const swiperClassModifier = `.${this.classList[1]}`;

        const swiperOptions = {
            modules: [Navigation, Autoplay, Pagination, Scrollbar, Mousewheel],
            slidesPerView: 'auto',
            spaceBetween: isNaN(gap) ? spaceBetween : gap,
            centeredSlides: this.dataset.align === 'center',
            centeredSlidesBounds: true,
            rewind: false,
            preventClicks: true,
            watchSlidesProgress: true,
            direction: 'horizontal',
            mousewheel: {forceToAxis: true},
            navigation: {
                nextEl: `${swiperClassModifier} > .${CLASS_NAME_SWIPER_BUTTON_NEXT}`,
                prevEl: `${swiperClassModifier} > .${CLASS_NAME_SWIPER_BUTTON_PREV}`,
            },
            observer: true,
        };

        if (this.getElementsByClassName('swiper-pagination')[0]) {
            swiperOptions.pagination = {el: `${swiperClassModifier} > .swiper-pagination`, clickable: true};
        }

        if (this.getElementsByClassName('swiper-scrollbar')[0]) {
            swiperOptions.scrollbar = {el: `${swiperClassModifier} > .swiper-scrollbar`, hide: false};
        }

        if (this.dataset.type === 'imageSlider') {
            swiperOptions.slidesPerGroup = layoutUnits.hasOwnProperty(columnSize) ? layoutUnits[columnSize].count : 1;
            swiperOptions.spaceBetween = isNaN(gap) ? 0 : gap;
        }

        if (this.dataset.type === 'imageGallery') {
            swiperOptions.slidesPerView = layoutUnits.mainColumn.count;
            swiperOptions.spaceBetween = isNaN(gap) ? 8 : gap;
        }

        if (this.dataset.autoplay) {
            swiperOptions.autoplay = {delay: 4000, disableOnInteraction: true};
        }

        this.swiper = new Swiper(this, swiperOptions);
        this.recalculateImageDimensions();
        this.recalculateSwiperButtonsTopPosition();
        this.swiper.el.classList.add('--loaded');
    }
}

window.customElements.define('kvik-swiper', SwiperElement);