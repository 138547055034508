import {requestIdleCallback} from './dom';
import {debounce} from './utils';


class KvikPageElement extends HTMLElement {

    connectedCallback() {
        const debounceHander = debounce(() => this.displayChange(), 250);
        window.addEventListener('resize', debounceHander);
        requestIdleCallback(() => this.displayChange());
    }

    displayChange() {
        this.style.display = this.areAllChildrenHidden() ? 'none' : 'block';
        if (this.parentElement.children.length === 1) {
            this.parentElement.style.display = this.style.display;
        }
    }

    areAllChildrenHidden() {
        this.style.display = 'block';
        this.parentElement.style.display = 'block';

        const children = this.children;
        if (children.length === 0) {
            return false;
        }

        return [...children].every(child => KvikPageElement.isElementHidden(child));
    }

    static isElementHidden(el) {
        return window.getComputedStyle(el).display === 'none';
    }
}

window.customElements.define('kvik-page', KvikPageElement);