const VEHICLE_ID_PLACEHOLDER = '__VEHICLE_ID_PLACEHOLDER__';
const USER_VEHICLE_ID_PLACEHOLDER = '__USER_VEHICLE_ID_PLACEHOLDER__';
const DATA_INIT = 'init';
const DATA_ID = 'id';
let userVehicleTemplate = null;

function createUserVehicleItem(data) {
    if (!userVehicleTemplate) {
        userVehicleTemplate = document.getElementById('userVehicle__itemTemplate');
        if (!userVehicleTemplate) {
            return null;
        }
    }

    const clone = userVehicleTemplate.content.cloneNode(true);
    const item = clone.querySelector('.userVehicle__item');
    if (!item) {
        return null;
    }

    item.dataset[DATA_ID] = data.id;

    let vehicleUrl = userVehicleTemplate.dataset.vehicleUrl
        .replace(VEHICLE_ID_PLACEHOLDER, data.carId);

    if (!data.isHistory) {
        vehicleUrl = vehicleUrl.replace(USER_VEHICLE_ID_PLACEHOLDER, data.id);
    } else {
        vehicleUrl = vehicleUrl.replace(USER_VEHICLE_ID_PLACEHOLDER, '');
    }

    const elements = {
        name: item.getElementsByClassName('userVehicle__name')[0],
        link: item.getElementsByClassName('userVehicle__link')[0],
        motorCodes: item.getElementsByClassName('userVehicle__motorCodes')[0],
        image: item.getElementsByClassName('userVehicle__image')[0],
        motorization: item.getElementsByClassName('userVehicle__motorization')[0],
        powerWithYears: item.getElementsByClassName('userVehicle__powerWithYears')[0],
        note: item.getElementsByClassName('userVehicle__note')[0],
        licensePlate: item.getElementsByClassName('userVehicle__licensePlate')[0],
        vin: item.getElementsByClassName('userVehicle__vin')[0]
    };

    if (data.name && elements.name) {
        elements.name.textContent = data.name;
    }
    if (elements.link) {
        elements.link.href = vehicleUrl;
        elements.link.textContent = `${data.manufacturer || ''} ${data.model || ''}`.trim();
    }
    if (elements.motorCodes && data.motorCodes?.length) {
        elements.motorCodes.textContent = ` • ${data.motorCodes.join(', ')}`;
    }
    if (elements.image) {
        elements.image.src = data.vehicleBrandLogoUrl || '';
        elements.image.alt = data.manufacturer || '';
    }
    if (elements.motorization && data.motorization) {
        elements.motorization.textContent = data.motorization;
        elements.motorization.href = vehicleUrl;
    }
    if (elements.powerWithYears) {
        let suffix = '';
        if (data.powerKw) {
            suffix += ` • ${data.powerKw} kW`;
        }
        if (data.yearFrom || data.yearTo) {
            suffix += ` • ${data.yearTo ? `${data.yearFrom} - ${data.yearTo}` : `${userVehicleTemplate.dataset.yearFromText || ''} ${data.yearFrom}`}`;
        }
        elements.powerWithYears.textContent = suffix;
    }
    if (elements.note && data.note) {
        elements.note.textContent = data.note;
    }

    if (data.licensePlate && elements.licensePlate) {
        const plate = elements.licensePlate.getElementsByClassName('vehicleLicensePlate')[0];
        if (plate) {
            plate.dataset.licensePlate = data.licensePlate;
            elements.licensePlate.classList.remove('--hidden');
        }
    } else if (data.vin && elements.vin) {
        elements.vin.textContent = '';
        const kvikVin = document.createElement('kvik-vin');
        kvikVin.textContent = data.vin;
        elements.vin.appendChild(kvikVin);
        elements.vin.classList.remove('--hidden');
    }

    const initElement = (selector, config) => {
        const el = item.querySelector(selector);
        if (!el) {
            return;
        }
        for (const [key, value] of Object.entries(config)) {
            if (key === 'href') {
                el.href = value;
            } else {
                el.dataset[key] = value;
            }
        }
        if (window.kvik_ajax?.initElement) {
            window.kvik_ajax.initElement(el);
        }
    };

    initElement('.userVehicle__parkButton', {
        ajaxShowLoader: '1',
        ajaxHref: userVehicleTemplate.dataset.parkVehicleUrl.replace(VEHICLE_ID_PLACEHOLDER, data.carId)
    });

    if (data.id) {
        initElement('.userVehicle__partsButton', {href: vehicleUrl});
        initElement('.userVehicle__editButton', {
            ajaxShowLoader: '1',
            ajaxHref: userVehicleTemplate.dataset.userVehicleEditUrl
                .replace(USER_VEHICLE_ID_PLACEHOLDER, data.id)
                .replace(VEHICLE_ID_PLACEHOLDER, data.carId)
        });
    }

    return item;
}

function initVehicleHistory() {
    const vehicleHistory = document.getElementsByClassName('userVehicle__vehicleHistory')[0];
    if (!vehicleHistory) {
        return;
    }

    if (vehicleHistory.dataset[DATA_INIT]) {
        return;
    }
    vehicleHistory.dataset[DATA_INIT] = '1';

    deleteVehicleHistory();

    let vehicleHistoryData = [];
    try {
        vehicleHistoryData = JSON.parse(localStorage.getItem('vehicleHistory') || '[]');
    } catch (e) {
        return;
    }

    if (!vehicleHistoryData.length) {
        vehicleHistory.remove();
        return;
    }

    const userVehicleList = document.getElementsByClassName('userVehicle__itemList')[0];
    let userVehicleIds = [];
    if (userVehicleList) {
        try {
            const userVehicles = JSON.parse(userVehicleList.dataset.userVehicles || '[]');
            userVehicleIds = userVehicles.map(v => v.vehicle.id);
        } catch (e) {
            return;
        }
    }

    const itemsContainer = vehicleHistory.getElementsByClassName('userVehicle__vehicleHistoryItems')[0];
    if (!itemsContainer) {
        return;
    }

    const fragment = document.createDocumentFragment();
    let needsStorageUpdate = false;
    vehicleHistoryData = vehicleHistoryData.filter(vehicle => {
        if (userVehicleIds.includes(vehicle.carId)) {
            needsStorageUpdate = true;
            return false;
        }
        return true;
    });

    if (needsStorageUpdate) {
        try {
            localStorage.setItem('vehicleHistory', JSON.stringify(vehicleHistoryData));
        } catch (e) {
            console.error('Failed to update vehicle history:', e);
        }
    }

    for (const vehicle of vehicleHistoryData) {
        const item = createUserVehicleItem({
            ...vehicle,
            name: '',
            motorization: vehicle.name,
            id: vehicle.carId,
            isHistory: true
        });
        if (item) {
            fragment.appendChild(item);
        }
    }

    if (fragment.children.length !== 0) {
        vehicleHistory.classList.toggle('--hidden', false);
        itemsContainer.appendChild(fragment);
    }
}

function initDeleteUserVehicle() {
    const deleteButton = document.getElementsByClassName('userVehicle__delete')[0];
    if (!deleteButton) {
        return;
    }

    if (deleteButton.dataset[DATA_INIT]) {
        return;
    }
    deleteButton.dataset[DATA_INIT] = '1';

    const newButton = deleteButton.cloneNode(true);
    deleteButton.parentNode.replaceChild(newButton, deleteButton);

    newButton.addEventListener('click', async (event) => {
        event.preventDefault();
        if (!confirm(newButton.dataset.confirmMessage)) {
            return;
        }

        try {
            await fetch('/api/user-vehicle/delete', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({id: newButton.dataset.userVehicleId}),
            });

            const link = document.createElement('a');
            link.href = newButton.dataset.userVehicleUrl;
            link.dataset.ajaxShowLoader = '1';
            link.dataset.ajaxHref = newButton.dataset.userVehicleUrl;
            link.style.display = 'none';
            document.body.appendChild(link);
            if (window.kvik_ajax?.initElement) {
                window.kvik_ajax.initElement(link);
            }
            link.click();
        } catch (error) {
        }
    });
}

function initUserVehicleList() {
    const userVehicleList = document.getElementsByClassName('userVehicle__itemList')[0];
    const vehicleHistoryList = document.getElementsByClassName('userVehicle__vehicleHistoryItems')[0];
    if (!userVehicleList && !vehicleHistoryList) {
        return;
    }

    if (userVehicleList.dataset[DATA_INIT]) {
        return;
    }
    userVehicleList.dataset[DATA_INIT] = '1';

    let userVehicles = [];
    try {
        userVehicles = JSON.parse(userVehicleList.dataset.userVehicles || '[]');
    } catch (e) {
    }

    let vehicleHistory = [];
    try {
        vehicleHistory = JSON.parse(localStorage.getItem('vehicleHistory') || '[]');
    } catch (e) {
    }

    const noUserVehiclesMessage = userVehicleList.getElementsByClassName('userVehicle__noMessage')[0];
    const noVehicleHistoryMessage = vehicleHistoryList ? vehicleHistoryList.getElementsByClassName('userVehicle__noMessage')[0] : null;
    const searchElement = document.getElementsByClassName('userVehicle__searchInput')[0];

    const fragment = document.createDocumentFragment();
    for (const userVehicle of userVehicles) {
        const item = createUserVehicleItem({
            name: userVehicle.name,
            model: userVehicle.vehicle.vehicleModelName,
            manufacturer: userVehicle.vehicle.vehicleBrandName,
            motorization: userVehicle.vehicle.name,
            carId: userVehicle.vehicle.id,
            id: userVehicle.id,
            yearFrom: new Date(userVehicle.vehicle.dateTimeFrom).getFullYear().toString(),
            yearTo: userVehicle.vehicle.dateTimeTo ? new Date(userVehicle.vehicle.dateTimeTo).getFullYear().toString() : null,
            powerKw: userVehicle.vehicle.powerKW,
            motorCodes: userVehicle.vehicle.motorCodes,
            note: userVehicle.note,
            vin: userVehicle.vin,
            licensePlate: userVehicle.licensePlateNumber,
            vehicleBrandLogoUrl: userVehicle.vehicle.vehicleBrandLogoUrl,
        });
        if (item) {
            fragment.appendChild(item);
        }
    }
    userVehicleList.appendChild(fragment);

    const filterVehicles = (searchValue) => {
        const normalizedSearch = searchValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        return [...userVehicles, ...vehicleHistory].filter(vehicle => {
            const values = vehicle.vehicle ? [
                vehicle.name?.toLowerCase() || '',
                vehicle.vehicle.vehicleName?.toLowerCase() || '',
                `${vehicle.vehicle.powerKW}kW`.toLowerCase(),
                vehicle.vehicle.motorCodes?.map(c => c.toLowerCase()).join(',') || '',
                vehicle.vin?.toLowerCase() || '',
                vehicle.note?.toLowerCase() || '',
                vehicle.licensePlateNumber?.toLowerCase() || '',
                new Date(vehicle.vehicle.dateTimeFrom).getFullYear().toString(),
                vehicle.vehicle.dateTimeTo ? new Date(vehicle.vehicle.dateTimeTo).getFullYear().toString() : ''
            ] : [
                vehicle.name?.toLowerCase() || '',
                vehicle.title?.toLowerCase() || '',
                vehicle.manufacturer?.toLowerCase() || '',
                `${vehicle.powerKw}kW`.toLowerCase(),
                vehicle.motorCodes?.map(c => c.toLowerCase()).join(',') || '',
                vehicle.yearFrom?.toString() || '',
                vehicle.yearTo?.toString() || ''
            ];
            return values.some(v => v.includes(normalizedSearch));
        }).map(v => v.vehicle ? v.id : v.carId);
    };

    const applyFilter = (filteredIds, searchValue) => {
        const allItems = document.getElementsByClassName('userVehicle__item');
        let visibleUserVehicles = 0;
        let visibleHistoryVehicles = 0;

        for (const item of allItems) {
            const itemId = parseInt(item.dataset[DATA_ID]);
            const isVisible = filteredIds.includes(itemId);
            item.classList.toggle('--hidden', !isVisible);
            if (isVisible) {
                item.closest('.userVehicle__itemList') ? visibleUserVehicles++ : visibleHistoryVehicles++;
            }
        }

        if (noUserVehiclesMessage) {
            noUserVehiclesMessage.classList.toggle('--visible', !visibleUserVehicles);
            if (searchValue) {
                noUserVehiclesMessage.textContent = noUserVehiclesMessage.dataset.message.replace('%search%', searchValue);
            }
        }
        if (noVehicleHistoryMessage) {
            noVehicleHistoryMessage.classList.toggle('--visible', !visibleHistoryVehicles);
            if (searchValue) {
                noVehicleHistoryMessage.textContent = noVehicleHistoryMessage.dataset.message.replace('%search%', searchValue);
            }
        }
    };

    if (searchElement) {
        const newSearchElement = searchElement.cloneNode(true);
        searchElement.parentNode.replaceChild(newSearchElement, searchElement);

        let debounceTimer;
        newSearchElement.addEventListener('input', ({target}) => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                applyFilter(filterVehicles(target.value), target.value);
            }, 150);
        });
        newSearchElement.value = '';
    }

    applyFilter([...userVehicles.map(v => v.id), ...vehicleHistory.map(v => v.carId)], '');
}

function deleteVehicleHistory() {
    try {
        const history = JSON.parse(localStorage.getItem('vehicleHistory') || '[]');
        if (history.some(v => !v.vehicleBrandLogoUrl)) {
            localStorage.removeItem('vehicleHistory');
        }
    } catch (e) {
    }
}

function initVehiclePreview() {
    const previewItemElement = document.getElementsByClassName('userVehicle__previewItem')[0];
    if (!previewItemElement) {
        return;
    }

    if (previewItemElement.dataset[DATA_INIT]) {
        return;
    }
    previewItemElement.dataset[DATA_INIT] = '1';


    if (previewItemElement.dataset.vehicle) {
        try {
            const vehicle = JSON.parse(previewItemElement.dataset.vehicle);
            const item = createUserVehicleItem({
                model: vehicle.vehicleModelName,
                manufacturer: vehicle.vehicleBrandName,
                motorization: vehicle.name,
                carId: vehicle.id,
                yearFrom: new Date(vehicle.dateTimeFrom).getFullYear().toString(),
                yearTo: vehicle.dateTimeTo ? new Date(vehicle.dateTimeTo).getFullYear().toString() : null,
                powerKw: vehicle.powerKW,
                motorCodes: vehicle.motorCodes,
                vehicleBrandLogoUrl: vehicle.vehicleBrandLogoUrl,
            });
            if (item) {
                previewItemElement.appendChild(item);
            }
        } catch (e) {

        }
    }

    if (previewItemElement.dataset.userVehicle) {
        try {
            const userVehicle = JSON.parse(previewItemElement.dataset.userVehicle);
            const item = createUserVehicleItem({
                name: userVehicle.name,
                model: userVehicle.vehicle.vehicleModelName,
                manufacturer: userVehicle.vehicle.vehicleBrandName,
                motorization: userVehicle.vehicle.name,
                carId: userVehicle.vehicle.id,
                id: userVehicle.id,
                yearFrom: new Date(userVehicle.vehicle.dateTimeFrom).getFullYear().toString(),
                yearTo: userVehicle.vehicle.dateTimeTo ? new Date(userVehicle.vehicle.dateTimeTo).getFullYear().toString() : null,
                powerKw: userVehicle.vehicle.powerKW,
                motorCodes: userVehicle.vehicle.motorCodes,
                note: userVehicle.note,
                vin: userVehicle.vin,
                licensePlate: userVehicle.licensePlateNumber,
                vehicleBrandLogoUrl: userVehicle.vehicle.vehicleBrandLogoUrl,
            });
            if (item) {
                previewItemElement.appendChild(item);
            }
        } catch (e) {

        }
    }
}

function initDeleteVehicleHistory() {
    const deleteHistoryButton = document.getElementsByClassName('userVehicle__vehicleHistoryDelete')[0];
    const vehicleHistory = document.getElementsByClassName('userVehicle__vehicleHistory')[0];
    if (!deleteHistoryButton || !vehicleHistory) {
        return;
    }

    if (deleteHistoryButton.dataset[DATA_INIT]) {
        return;
    }
    deleteHistoryButton.dataset[DATA_INIT] = '1';

    const newButton = deleteHistoryButton.cloneNode(true);
    deleteHistoryButton.parentNode.replaceChild(newButton, deleteHistoryButton);

    newButton.addEventListener('click', (event) => {
        setTimeout(() => {
            event.preventDefault();
            if (!confirm(newButton.dataset.confirmMessage)) {
                return;
            }
            if (vehicleHistory) {
                vehicleHistory.remove();
            }
            localStorage.removeItem('vehicleHistory');
        }, 100);
    });
}

onDOMReady(() => {
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, () => {
        initVehicleHistory();
        initVehiclePreview();
        initDeleteVehicleHistory();
        initDeleteUserVehicle();
        initUserVehicleList();
    });
});