// jQuery free
import {onDOMReady} from './dom';

const CLASS_BLOCK_NUMBER_INPUT = 'numberInput';
const DATA_SUBMIT_CHANGE = 'data-submit-change';
const DATA_NUMBER_VALUE = 'data-number-value';


const createNumberInput = function (input) {
    if (input.parentElement.classList.contains(CLASS_BLOCK_NUMBER_INPUT)) {
        return;
    }


    const numberInputSpan = document.createElement('span');
    numberInputSpan.className = 'numberInput';
    numberInputSpan.innerHTML = `
        <button type="button" class="numberInput__minus">&minus;</button>
        <button type="button" class="numberInput__plus">&plus;</button>
    `;

    input.parentElement.insertBefore(numberInputSpan, input);
    numberInputSpan.appendChild(input);


    const form = input.closest('form');
    const minusButton = numberInputSpan.getElementsByClassName('numberInput__minus')[0];
    const plusButton = numberInputSpan.getElementsByClassName('numberInput__plus')[0];
    const step = parseInt(input.getAttribute('step')) || 1;
    const min = parseInt(input.getAttribute('min')) || 0;
    const unit = input.getAttribute('data-unit') || '';
    let max = parseInt(input.getAttribute('max'));

    if (!isNaN(max)) {
        max = max - (max % step);
    }
    if (!isNaN(max) && max < min) {
        max = min;
    }

    input.setAttribute('type', 'text');
    if (unit === '') {
        // it shows numeric keyboard on mobile phones
        input.setAttribute('pattern', '[0-9]*');
    } else {
        input.removeAttribute('pattern');
    }


    const normalizeValue = (value) => {
        const currentVal = parseInt(value) || min;
        let newVal = currentVal - (currentVal % step);
        if (!isNaN(max) && newVal > max) {
            newVal = max;
        }

        return newVal;
    };


    const setValue = (value) => {
        const newVal = normalizeValue(value);
        input.setAttribute(DATA_NUMBER_VALUE, newVal);
        input.value = newVal + unit;
    };


    // Initial value normalization and setting to data-attribute.
    setValue(input.value);


    const stepPlus = () => {
        setValue(normalizeValue(input.value) + step);
        input.dispatchEvent(new Event('change'));
    };


    const stepMinus = () => {
        setValue(normalizeValue(input.value) - step);
        input.dispatchEvent(new Event('change'));
    };


    let onChangeTimerId;
    const onChange = () => {
        setValue(input.value);
        if (input.hasAttribute(DATA_SUBMIT_CHANGE)) {
            if (onChangeTimerId) {
                clearTimeout(onChangeTimerId);
            }
            onChangeTimerId = setTimeout(function () {
                form.submit();
            }, 300);
        }
    };


    let onKeyUpTimerId;
    const onKeyUp = () => {
        clearTimeout(onKeyUpTimerId);
        onKeyUpTimerId = setTimeout(function () {
            if (input.value !== '') {
                setValue(input.value);
            }
        }, 500);
    };


    plusButton.addEventListener('click', (event) => {
        event.preventDefault();
        if (!input.disabled) {
            stepPlus();
        }
    });
    minusButton.addEventListener('click', (event) => {
        event.preventDefault();
        if (!input.disabled) {
            stepMinus();
        }
    });
    input.addEventListener('click', function () {
        input.setSelectionRange(0, input.value.length);
    });
    input.addEventListener('change', onChange);
    input.addEventListener('keyup', onKeyUp);
};

const initInDOM = function () {
    for (const input of document.querySelectorAll('input[type=number]')) {
        createNumberInput(input);
    }
};

onDOMReady(initInDOM);
document.addEventListener(window.kvik_ajax.EVENT_KVIK_AJAX_SNIPPETS_UPDATED, initInDOM);
document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL_BEFORE, initInDOM);