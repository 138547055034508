import { onDOMReady } from './dom';

onDOMReady(function () {
    const deliveryDateTimeConfirmationForm = document.getElementById('kvikymart-shopping-cart-delivery-date-time-confirmation-form');

    if (!deliveryDateTimeConfirmationForm) {
        return;
    }

    const recapitulationForm = document.getElementById('kvikymart-shopping-cart-recapitulation-form');
    const orderCartOverviewDeliveryDateTimes = document.getElementsByClassName('orderCartOverview__deliveryDateTime');

    if (!recapitulationForm || !orderCartOverviewDeliveryDateTimes.length) {
        return;
    }

    const orderCartOverviewDeliveryDateTime = orderCartOverviewDeliveryDateTimes[0];
    const orderCartOverviewDeliveryDateTimeText = orderCartOverviewDeliveryDateTime.querySelector('strong');
    const hiddenField = recapitulationForm.querySelector('input[name="deliveryDateTimeConfirmation"]');
    const radioButtons = deliveryDateTimeConfirmationForm.querySelectorAll('input[type="radio"][name="deliveryDateTimeConfirmation[deliveryDateTimeConfirmation]"]');

    radioButtons.forEach(function (radio) {
        radio.addEventListener('change', function () {
            hiddenField.value = radio.value;

            const label = radio.closest('label');
            if (label) {
                orderCartOverviewDeliveryDateTimeText.textContent = label.textContent.trim();
            }
        });
    });

    const submitButton = recapitulationForm.querySelector('input[type="submit"]:not([id="frm-cartOrderSubmit-submited"])');
    const originalOnClick = submitButton.getAttribute('onclick');
    submitButton.removeAttribute('onclick');

    const handleSubmit = function (event) {
        event.preventDefault();
        if (window.Nette && window.Nette.validateForm(deliveryDateTimeConfirmationForm)) {
            // Restore original onclick and trigger the original click behavior
            submitButton.setAttribute('onclick', originalOnClick);
            submitButton.removeEventListener('click', handleSubmit);
            submitButton.click();
        }
    };

    submitButton.addEventListener('click', handleSubmit);
});
