// jQuery free
export function requestIdleCallback(callback) {
    if (window.requestIdleCallback) {
        window.requestIdleCallback(callback);
    } else if (window.requestAnimationFrame) {
        window.requestAnimationFrame(callback);
    } else {
        callback();
    }
}

export function onHtmlLayoutReady(callbackFunc) {
    if (window.kvik_html_layout_ready) {
        requestIdleCallback(callbackFunc);
    } else {
        document.addEventListener('kvik_html_layout_ready', () => requestIdleCallback(callbackFunc));
    }
}


export function onDOMReady(callbackFunc, idle = true) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        idle ? requestIdleCallback(callbackFunc) : callbackFunc();
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', idle ? () => requestIdleCallback(callbackFunc) : callbackFunc);
    } else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                idle ? requestIdleCallback(callbackFunc) : callbackFunc();
            }
        });
    }
}

;