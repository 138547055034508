// jQuery free
import {onHtmlLayoutReady, requestIdleCallback} from './dom';
import {getCart} from './payload';

function formatMoney(number, currencyCode, localeTag = null) {
    localeTag = localeTag ?? document.documentElement.lang;

    const numberFomratter = new Intl.NumberFormat(localeTag, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    return numberFomratter.format(number,);
}

const render = () => {
    const cartData = getCart();
    if (!cartData) {
        return;
    }

    const buttonCellElement = document.querySelector('.layoutHeaderMainBar__buttonCell.--shoppingCart');
    if (!buttonCellElement) {
        return;
    }

    const buttonElement = buttonCellElement.getElementsByClassName('layoutHeaderMainBar__button')[0];
    const buttonLabelElement = buttonCellElement.getElementsByClassName('layoutHeaderMainBar__button__label')[0];
    const buttonBadgeElement = buttonCellElement.getElementsByClassName('layoutHeaderMainBar__button__badge')[0];

    if (cartData.count) {
        const formattedMoney = formatMoney(cartData.totalPrice.amountWithVat, cartData.totalPrice.currencyCode);
        buttonLabelElement.textContent = `${cartData.count} ${buttonCellElement.dataset.labelPieces} / ${formattedMoney}`;
        buttonElement.classList.add('--highlighted');
        if (buttonBadgeElement) {
            buttonBadgeElement.textContent = `${cartData.count}`;
        }
    } else {
        buttonLabelElement.textContent = buttonCellElement.dataset.label;
        buttonElement.classList.remove('--highlighted');
        if (buttonBadgeElement) {
            buttonBadgeElement.remove();
        }
    }
};

document.addEventListener(window.kvik_ajax.EVENT_KVIK_AJAX_PAYLOAD, function () {
    requestIdleCallback(render);
});

onHtmlLayoutReady(render);