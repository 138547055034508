// jQuery free
import './layoutUnits';
import './picture';
import './closestPolyfill';
import './hackIconImagePagePart';
import './bodyScrollLock';
import './dropdown';
import './layoutHeader';
import './layoutHeaderCartButton';
import './page';
import './numberInput';
import './offsetAnchor';
import './productOeNumbers';
import './productDetailAddToCart';
import './shoppingCartLogin';
import './shoppingCartPartialDelivery';
import './shoppingCartRegister';
import './sidebarSticky';
// import './sidebarTree';
import './vehicleManufactures';
import './vehicleModels';
import './vehicleHistory';
import './locale';
import './vehicleSearch';
import './vehicleSelection';
import './vehicleUnselect';
import './lazyload';
import './licensePlateSearch';
import './searchHistory';
import './missingPriceToFreeDelivery';
import './shoppingCartLoginRegisterContinue';
import './shoppingCartMultipleCart';
import './layoutHeaderSearch';
import './searchAutocomplete';
import './scrollX';
import './filters';
import './yearSlider';
import './categorySelection';
import './swiper';
import './showHidden';
import './vehicleSchema';
import './vehicleResult';
import './vehicleLicensePlate';
import './userVehicle';
import './mobileApp';
import './accordion';
import './shoppingCartRecapitulation';

import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
import balanceText from 'balance-text';

balanceText();