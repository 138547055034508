// jQuery free
import {onDOMReady, requestIdleCallback} from './dom';
import {decodeBlurHash} from 'fast-blurhash';

function isImgHidden(imgElement) {
    let parent = imgElement;

    while (parent) {
        if (window.getComputedStyle(parent).display === 'none') {
            return true;
        }
        parent = parent.parentElement;
    }

    return false;
}


/**
 * @param {HTMLImageElement} imgElement
 * @param {HTMLPictureElement} pictureElement
 */
function drawBlurhash(imgElement, pictureElement) {
    if (!imgElement.classList.contains('loaded') && !isImgHidden(pictureElement)) {
        // decode blurHash image
        const widthInPx = 32;
        const heightInPx = Math.ceil(widthInPx / (imgElement.width || 1) * (imgElement.height || 1));
        const pixels = decodeBlurHash(pictureElement.dataset.bh, widthInPx, heightInPx);

        // draw it on canvas
        const canvas = document.createElement('canvas');
        canvas.width = widthInPx;
        canvas.height = heightInPx;
        const context = canvas.getContext('2d');
        const imageData = context.createImageData(widthInPx, heightInPx);
        imageData.data.set(pixels);
        context.putImageData(imageData, 0, 0);

        // here is the most important part because if you don't replace you will get a DOM 18 exception.
        const dataUri = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

        if (!imgElement.classList.contains('loaded')) {
            imgElement.style.backgroundImage = `url('${dataUri}')`;
            if (window.getComputedStyle(imgElement).backgroundSize === 'auto') {
                imgElement.style.backgroundSize = '100% 100%';
            }
            imgElement.style.backgroundPosition = 'center';
            imgElement.style.backgroundRepeat = 'no-repeat';
            imgElement.style.backgroundColor = 'inherit';
        }
    }

    delete pictureElement.dataset.bh;
}


/**
 * @param {HTMLPictureElement} pictureElement
 */
function pictureInit(pictureElement) {
    if (pictureElement.dataset.initPicture || !pictureElement.dataset.bh || !pictureElement.id.startsWith('kvik_picture')) {
        return;
    }
    pictureElement.dataset.initPicture = '1';

    const imgElement = pictureElement.getElementsByTagName('img')[0];
    if (!imgElement) {
        return;
    }

    const unsetStyle = function () {
        imgElement.style.backgroundImage = '';
        imgElement.style.backgroundColor = 'inherit';
    };

    if (imgElement.classList.contains('loaded')) {
        if (pictureElement.dataset.avg) {
            // reset inline styles set in ImageHtmlRenderer
            requestIdleCallback(unsetStyle);
        }
        return;
    }

    imgElement.addEventListener('load', unsetStyle);
    imgElement.onerror = () => {
        imgElement.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // 1px transparent GIF
    };

    if (imgElement.loading === 'lazy' || imgElement.decoding === 'async') {
        requestIdleCallback(() => drawBlurhash(imgElement, pictureElement));
    } else {
        drawBlurhash(imgElement, pictureElement);
    }
}

window.kvik_picture = pictureInit;

onDOMReady(function () {
    for (const pictureElement of document.getElementsByTagName('picture')) {
        pictureInit(pictureElement);
    }
}, true);