// jQuery free
const API_ENDPOINT = '/api/shopfront/search/autocomplete-stream';

let elements = {
    aibg: null,
    autocomplete: null,
    spinner: null,
    content: null,
    history: null,
    showAllAnchor: null
};

const shortNames = {
    AutocompleteProduct: 'AutocompleteProduct',
    AutocompleteCategory: 'AutocompleteCategory',
    AutocompleteVehicle: 'AutocompleteVehicle',
}

let headings = {
    [shortNames.AutocompleteProduct]: 'Products',
    [shortNames.AutocompleteCategory]: 'Categories',
    [shortNames.AutocompleteVehicle]: 'Vehicles',
};

export function setResultsVisibility(isAutocompleteActive = false) {
    elements.history ??= document.querySelector('.searchHistory');
    elements.autocomplete.hidden = !isAutocompleteActive;
    elements.history.hidden = isAutocompleteActive;
}

export function setSpinnerVisibility(isActive = false) {
    if (!elements.spinner) {
        elements.autocomplete.insertAdjacentHTML('beforeend', `<div class='searchAutocomplete__spinner'>${window.km_spinner.html}</div>`);
        elements.spinner = elements.autocomplete.getElementsByClassName('searchAutocomplete__spinner')[0];
    }

    if ((elements.spinner.hidden && !isActive) || (!elements.spinner.hidden && isActive)) {
        return;
    }

    elements.spinner.hidden = !isActive;
    elements.content.hidden = isActive;

    if (elements.aibg) {
        elements.aibg.hidden = !isActive;
        elements.aibg.style.animationName = 'aibg';
    }
}

const eventKeys = {
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown'
};

/**
 * @param {HTMLFormElement} searchForm
 * @param {HTMLInputElement} searchInput
 */
export default function searchAutocomplete({searchForm, searchInput}) {
    elements.aibg = document.getElementsByClassName('layoutHeaderDropdownSearch__aibg')[0];
    elements.autocomplete = document.getElementsByClassName('searchAutocomplete')[0];
    if (!Number(elements.autocomplete?.dataset?.enabled)) {
        return;
    }

    let timeoutReference = null;
    let lastTerm = null;

    const template = elements.autocomplete.querySelector('#searchAutocomplete__itemTemplate');
    elements.content = elements.autocomplete.getElementsByClassName('searchAutocomplete__content')[0];
    elements.showAllAnchor = elements.content.lastElementChild;

    headings.AutocompleteCategory = elements.autocomplete.dataset.categoryHeading;
    headings.AutocompleteProduct = elements.autocomplete.dataset.productHeading;
    headings.AutocompleteVehicle = elements.autocomplete.dataset.vehicleHeading;

    const parseAndRenderStream = (reader) => {
        const decoder = new TextDecoder();
        let buffer = '';

        const stream = new ReadableStream({
            start(controller) {
                let hasData = false;

                const push = () => {
                    reader.read().then(({done, value}) => {
                        if (done) {
                            controller.close();
                            setSpinnerVisibility(false);

                            if (!hasData) {
                                elements.showAllAnchor.hidden = false; // Display last show all anchor
                            }

                            return;
                        }

                        buffer += decoder.decode(value, {stream: true});
                        const parts = buffer.split('</script>');
                        buffer = parts.pop();

                        parts.forEach((part) => {
                            const jsonStart = part.indexOf('{');
                            if (jsonStart !== -1) {
                                try {
                                    const data = JSON.parse(part.substring(jsonStart));
                                    renderItem(data);

                                    hasData = true;
                                    elements.content.hidden = false; // Show content and keep spinner
                                } catch (e) {
                                    console.error('Error parsing JSON:', e);
                                }
                            }
                        });

                        controller.enqueue(value);
                        push();
                    }).catch((error) => {
                        console.error('Stream reading error:', error);
                        controller.error(error);
                    });
                };
                push();
            }
        });


        return new Response(stream);
    };

    const renderItem = ({_shortName, url, name, imageUrl, price, availabilityShortLabel, isAvailable, isAbleToOrder, stockStatus, vin, parentCategoryNames, productTypeName, availabilityLabelColor}) => {
        let groupContainer = elements.content.querySelector(`.searchAutocomplete__group[data-type="${_shortName}"]`);

        if (!groupContainer) {
            groupContainer = document.createElement('div');
            groupContainer.className = 'searchAutocomplete__group';
            groupContainer.dataset.type = _shortName;

            const heading = document.createElement('span');
            heading.className = 'searchAutocomplete__group__heading';
            heading.textContent = headings[_shortName];
            groupContainer.appendChild(heading);

            elements.content.insertBefore(groupContainer, elements.showAllAnchor);

            const clonedShowAllAnchor = elements.showAllAnchor.cloneNode(true);
            clonedShowAllAnchor.hidden = false;
            clonedShowAllAnchor.onmouseover = () => clonedShowAllAnchor.focus();
            groupContainer.appendChild(clonedShowAllAnchor);
        }

        const clone = template.content.cloneNode(true);
        const anchor = clone.querySelector('a');
        const [imageElement, svgiconElement, headingElement, availabilityLabelElement, priceElement, subHeadingElement] = [
            anchor.querySelector('img'),
            anchor.querySelector('.searchAutocomplete__item__imageWrap .svgicon'),
            anchor.getElementsByClassName('searchAutocomplete__item__heading')[0],
            anchor.getElementsByClassName('searchAutocomplete__item__availabilityLabel')[0],
            anchor.getElementsByClassName('searchAutocomplete__item__price')[0],
            anchor.getElementsByClassName('searchAutocomplete__item__subHeading')[0]
        ];

        anchor.href = url;

        if (imageUrl) {
            imageElement.src = imageUrl;
            imageElement.hidden = false;
            imageElement.alt = name;
        } else if (imageElement.dataset[`src${_shortName}`]) {
            imageElement.src = imageElement.dataset[`src${_shortName}`];
            imageElement.hidden = false;
        } else if (_shortName === shortNames.AutocompleteCategory) {
            svgiconElement.style.display = 'block';
        }

        anchor.onmouseover = () => anchor.focus();

        headingElement.textContent = productTypeName ?? name;
        if (availabilityShortLabel) {
            Object.assign(availabilityLabelElement, {innerHTML: availabilityShortLabel, hidden: false});
        }

        if (isAbleToOrder) {
            availabilityLabelElement.classList.add('--ableToOrder');
        }
        if (isAvailable) {
            availabilityLabelElement.classList.add('--available');
        }
        if (stockStatus) {
            availabilityLabelElement.classList.add('--' + stockStatus);
        }
        if (price) {
            Object.assign(priceElement, {textContent: `${price.amountWithVat} ${price.currencyCode}`, hidden: false});
        }
        if (availabilityLabelColor) {
            availabilityLabelElement.classList.add('--' + availabilityLabelColor);
        }
        if (vin) {
            Object.assign(subHeadingElement, {textContent: vin, hidden: false});
        }
        if (parentCategoryNames) {
            parentCategoryNames.push(name);
            Object.assign(subHeadingElement, {textContent: parentCategoryNames.join(' → '), hidden: false});
        }

        const showAllBtn = groupContainer.querySelector('.searchAutocomplete__item.--showAll');

        groupContainer.insertBefore(anchor, showAllBtn);
    };

    const onInputHandler = () => {
        const term = searchInput.value.trim();
        if (lastTerm === term) {
            return;
        }

        lastTerm = term;

        clearTimeout(timeoutReference);
        if (!term || term.length <= 1) {
            setResultsVisibility(false);
            setSpinnerVisibility(false);

            return;
        }

        timeoutReference = setTimeout(() => {
            setResultsVisibility(true);
            setSpinnerVisibility(true);

            while (elements.content.firstElementChild && elements.content.childElementCount > 1) {
                elements.content.firstElementChild.remove();
            }

            elements.showAllAnchor.hidden = true;

            fetch(`${API_ENDPOINT}?term=${term}`, {
                method: 'GET',
                mode: 'same-origin',
                headers: {'X-Requested-With': 'XMLHttpRequest'}
            }).then(response => {
                elements.showAllAnchor.href = elements.showAllAnchor.getAttribute('data-href').replace('__PLACEHOLDER__', encodeURIComponent(term));
                parseAndRenderStream(response.body.getReader());
            }).catch(error => {
                console.error('Error fetching data:', error);
                setSpinnerVisibility(false);
            });
        }, 500);
    };

    searchInput.oninput = onInputHandler;
    if (document.activeElement === searchInput) {
        onInputHandler();
    }
    searchForm.onkeydown = (event) => {
        const key = event.key;
        if (elements.autocomplete.hidden || elements.content.hidden) {
            return;
        }

        const groups = Array.from(elements.content.getElementsByClassName('searchAutocomplete__group'));
        const items = Array.from(elements.content.getElementsByClassName('searchAutocomplete__item'));
        if (!Object.values(eventKeys).includes(key)) {
            return;
        }
        event.preventDefault();

        const currentIndex = items.indexOf(document.activeElement);

        if (key === eventKeys.arrowDown && currentIndex < items.length - 1) {
            items[currentIndex + 1].focus();
        }

        if (key === eventKeys.arrowUp) {
            if (currentIndex > 0) {
                items[currentIndex - 1].focus();
            } else if (currentIndex === 0) {
                searchInput.focus();
            } else {
                const currentGroup = groups.find(group => group.contains(document.activeElement));
                const previousGroup = groups[groups.indexOf(currentGroup) - 1];
                previousGroup?.querySelector('.searchAutocomplete__item:last-child')?.focus();
            }
        }
    };
}